/**
 * External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO( { description, lang, meta, keywords, title } ) {
	return (
		<StaticQuery
			query={ detailsQuery }
			render={ ( data ) => {
				const metaDescription =
					description || data.site.siteMetadata.description;
				const titleText = title ?
					`${ title } | ${ data.site.siteMetadata.title }` :
					data.site.siteMetadata.title;
				return (
					<Helmet
						htmlAttributes={ {
							lang,
						} }
						title={ titleText }
						titleTemplate={ `%s` }
						meta={ [
							{
								name: `description`,
								content: metaDescription,
							},
							{
								property: `og:title`,
								content: title,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								name: `twitter:card`,
								content: `summary`,
							},
							{
								name: `twitter:creator`,
								content: data.site.siteMetadata.author,
							},
							{
								name: `twitter:title`,
								content: title,
							},
							{
								name: `twitter:description`,
								content: metaDescription,
							},
						]
							.concat(
								keywords.length > 0 ?
									{
										name: `keywords`,
										content: keywords.join( `, ` ),
									} :
									[]
							)
							.concat( meta ) }
					/>
				);
			} }
		/>
	);
}

SEO.defaultProps = {
	lang: `es`,
	meta: [],
	keywords: [],
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf( PropTypes.string ),
	title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				author
			}
		}
	}
`;
