/**
 * External Dependencies
 */
import React from 'react';

/**
 * Internal Dependencies
 */
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import RSVP from '../../components/rsvp';

const SinglePage = ( props ) => {
	const {
		pageContext: { id, slug, pageId, title, content, excerpt },
	} = props;
	const contentFixedUrls = content.replace(
		/https:\/\/data\.melandkelly\.party/gi,
		''
	);

	return (
		<Layout>
			<SEO title={ title } description={ excerpt } />
			<article
				data-id={ id }
				id={ `post-${ pageId }` }
				className={ `post-${ pageId } post type-page hentry entry slug-${ slug }` }
			>
				<div
					className="entry-content"
					dangerouslySetInnerHTML={ { __html: contentFixedUrls } }
				/>

				{ slug === 'rsvp' && <RSVP /> }
			</article>
		</Layout>
	);
};

export default SinglePage;
