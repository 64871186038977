/* eslint-disable jsx-a11y/label-has-for */
/**
 * External Dependencies
 */
import React from 'react';

const RSVP = () => (
	<form name="rsvp" method="POST" data-netlify="true" action="/thanks/">
		<input type="hidden" name="form-name" value="rsvp" />
		<div className="rsvp-fieldset">
			<label className="rsvp-question" htmlFor="name">
				Your Name
			</label>
			<input id="name" type="text" name="name" />
		</div>

		<fieldset className="rsvp-fieldset rsvp-radiogroup">
			<legend className="rsvp-question">Will you be able to make it?</legend>

			<label>
				<input type="radio" name="rsvp" value="yes" />
				Accept
			</label>

			<label>
				<input type="radio" name="rsvp" value="no" />
				Decline
			</label>
		</fieldset>

		<fieldset className="rsvp-extras">
			<legend>Optional Details</legend>

			<div className="rsvp-fieldset">
				<label className="rsvp-question" htmlFor="guests">
					Names of your guests
				</label>
				<textarea id="guests" rows="2" name="guests" />
			</div>

			<div className="rsvp-fieldset">
				<label className="rsvp-question" htmlFor="diet">
					Dietary requirements
				</label>
				<textarea id="diet" rows="1" name="diet" />
			</div>
		</fieldset>

		<div style={ { textAlign: 'center' } }>
			<div className="rsvp-button__container">
				<input type="submit" value="Submit RSVP" />
			</div>
		</div>
	</form>
);

export default RSVP;
