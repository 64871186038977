/**
 * External Dependencies
 */
import React from 'react';

const Footer = () => (
	<footer id="colophon" className="site-footer">
		<div className="site-info">
			<a href="https://wordpress.org/" className="imprint">
				Yep, it&#39;s WordPress.
			</a>
		</div>
	</footer>
);

export default Footer;
