/**
 * External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal Dependencies
 */
import Header from './header';
import Footer from './footer';
import '../../styles/style.scss';

const Layout = ( { children, classNames = '' } ) => (
	<div id="page" className={ `site ${ classNames }` }>
		<a className="skip-link screen-reader-text" href="#content">
			Skip to content
		</a>

		<Header />

		<div id="content" className="site-content">
			<main id="main" className="site-main">
				{ children }
			</main>
		</div>

		<Footer />
	</div>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
