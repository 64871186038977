/**
 * External Dependencies
 */
import React from 'react';
import { Link } from 'gatsby';

const Menu = ( { home } ) => (
	<nav id="site-navigation" className="main-navigation" aria-label="Top Menu">
		<ul id="menu-main-menu" className="main-menu">
			<li className="menu-item site-title">
				<Link to="/" rel="home">
					{ home }
				</Link>
			</li>
			<li className="menu-item">
				<Link to="/location">Location</Link>
			</li>
			<li className="menu-item">
				<Link to="/our-story">Our Story</Link>
			</li>
			<li className="menu-item">
				<a href="https://www.zola.com/registry/kellyandmel">Registry</a>
			</li>
			<li className="menu-item is-aligned-right">
				<Link to="/rsvp">RSVP</Link>
			</li>
		</ul>
	</nav>
);

export default Menu;
