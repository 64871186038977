/**
 * External Dependencies
 */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

/**
 * Internal Dependencies
 */
import Menu from './menu';

const HEADER_QUERY = graphql`
	query SiteTitleQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;

const Header = () => (
	<StaticQuery
		query={ HEADER_QUERY }
		render={ ( data ) => {
			return (
				<header id="masthead" className="site-header">
					<div className="site-branding">
						<Menu home={ data.site.siteMetadata.title } />
					</div>
				</header>
			);
		} }
	/>
);

export default Header;
